import { AsciiEffect,} from 'three-stdlib';
import { useFrame, useThree, } from '@react-three/fiber'
import { useEffect, useMemo, useLayoutEffect } from 'react';

export function AsciiRenderer({
    renderIndex = 1,
    bgColor = 'black',
    fgColor = 'white',
    characters = ' .:-+*=%@#bluetrails',
    invert = true,
    color = false,
    resolution = 0.3
}) {
    // Reactive state
    const { size, gl, scene, camera } = useThree();

    // Create effect
    const effect = useMemo(() => {
        const effect = new AsciiEffect(gl, characters, { invert, color, resolution })
        effect.domElement.style.position = 'absolute';
        effect.domElement.style.top = '0px';
        effect.domElement.style.left = '0px';
        effect.domElement.style.pointerEvents = 'none';
        effect.domElement.style.overflow = 'hidden';
        effect.domElement.style.width = '100%';
        effect.domElement.style.height = '100%';


        return effect
    }, [characters, invert, color, resolution, gl])

    // Styling
    useLayoutEffect(() => {
        effect.domElement.style.color = fgColor
        effect.domElement.style.backgroundColor = bgColor
    }, [fgColor, bgColor, effect.domElement.style])

    // Append on mount, remove on unmount
    useEffect(() => {
        gl.domElement.style.opacity = '0'
        gl.domElement.parentNode.parentNode.appendChild(effect.domElement)
        return () => {
            gl.domElement.style.opacity = '1'
            gl.domElement.parentNode.parentNode.removeChild(effect.domElement)
        }
    }, [effect, gl.domElement.style, gl.domElement.parentNode])

    // Set size
    useEffect(() => {
        effect.setSize(size.width, size.height);
    }, [effect, size])

    // Take over render-loop (that is what the index is for)
    useFrame((state) => {
        effect.render(scene, camera)
    }, renderIndex)

    // This component returns nothing, it is a purely logical
}