import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';
import { Html } from '@react-three/drei';

import Subscribe from './Subscribe';
import { Links } from './Links';

import prof from './pics/prof_high_exp.jpeg';

export function ProfPic(props) {
  const texture = useLoader(THREE.TextureLoader, prof);
  var geometry = [26, 26, 0.01];
  return (
    <mesh {...props}>
      <boxGeometry attach="geometry" args={geometry} />
      <meshBasicMaterial attach="material" map={texture} />
      <Html className="content" position={[-9, 3, 1.5]} transform>
        <div className='home'>
          <Links />
          <br />
          <Subscribe />
        </div>
      </Html>
    </mesh>
  )
}