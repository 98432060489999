import { TextGeometry, FontLoader } from 'three-stdlib';
import { extend } from '@react-three/fiber'
import Helvetiker from 'three/examples/fonts/helvetiker_regular.typeface.json';

extend({ TextGeometry })

export function Logo(props) {
    const font = new FontLoader().parse(Helvetiker);
    const textOptions = {
        font,
        size: 4,
        height: 1,
        bevelThickness: 10
    };
    return (
        <mesh
            {...props}>
            <textGeometry args={["bluetrails", textOptions]} />
            <meshStandardMaterial color="blue" />
        </mesh>
    )
}

