import { Canvas } from '@react-three/fiber';
import { useEffect } from 'react';

import { ProfPic } from './ProfPic';
import { Logo, } from './Logo.js';
import { Float } from './Float.js';
import { AsciiRenderer } from './AsciiRenderer.js';

import './App.css';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <div className='logo'>
        <Canvas camera={{ position: [0, 0, 30] }}>
          <Float>
            <ProfPic
              position={[3, -5.5, 0]} />
          </Float>
          <Float>
            <Logo position={[-10.6, 8.5, 0]} />
          </Float>

          <color attach="background" args={['black']} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[10, 10, 10]} />
          <AsciiRenderer fgColor="blue" bgColor="white" />
        </Canvas>
      </div>

    </div>

  )
}

export default App;
