export function Links() {
    // OVERSOUL
    // return (<div>
    //     {/* <a href="https://push.fm/ps/oversoul">oversoul:out now!</a><br /><br /> */}
    //     <div>OVERSOUL:OUT NOW</div><br />
    //     <a href="https://open.spotify.com/album/5Tq6Qg0K0X8z1hw2p94NQ0" target="_blank" rel="noopener noreferrer">spotify</a><br />
    //     <a href="https://music.apple.com/us/album/oversoul/1693122113?i=1693122114" target="_blank" rel="noopener noreferrer">apple music</a><br />
    //     <a href="https://www.instagram.com/bluetrails_/" target="_blank" rel="noopener noreferrer">  instagram</a><br />
    //     <a href="https://www.tiktok.com/@bluetrails" target="_blank" rel="noopener noreferrer">  tiktok</a><br />
    //     <a href="https://youtu.be/lWVVL4f7ISo" target="_blank" rel="noopener noreferrer">  youtube</a><br />
    //     <a href="https://bluetrails.bandcamp.com/track/oversoul" target="_blank" rel="noopener noreferrer">   bandcamp</a><br />
    //     {/* <a href="https://twitter.com/bluetrails_" target="_blank" rel="noopener noreferrer"> twitter</a><br />
    //     <a href="https://www.facebook.com/profile.php?id=100089853300338" target="_blank" rel="noopener noreferrer"> facebook</a><br />
    //     <a href="https://soundcloud.com/bluetrails" target="_blank" rel="noopener noreferrer">soundcloud</a><br /> */}
    // </div>)

    // RUN
    // return (<div>
    //     <div>RUN:OUT NOW</div><br />
    //     <a href="https://youtu.be/LU-UqYpz4SQ" target="_blank" rel="noopener noreferrer">MUSIC VIDEO</a><br /><br />
    //     <a href="https://open.spotify.com/album/4tpxPxP3SrfmuuGjdTrs2x?si=Fxx-jBT3R3ew3E5tj6rQLA" target="_blank" rel="noopener noreferrer">spotify</a><br />
    //     <a href="https://music.apple.com/us/album/run/1699559548?i=1699559551" target="_blank" rel="noopener noreferrer">apple music</a><br />
    //     <a href="https://www.instagram.com/bluetrails_/" target="_blank" rel="noopener noreferrer">instagram</a><br />
    //     <a href="https://www.tiktok.com/@bluetrails" target="_blank" rel="noopener noreferrer">tiktok</a><br />
    //     <a href="https://bluetrails.bandcamp.com/track/run" target="_blank" rel="noopener noreferrer">bandcamp</a><br />
    //     {/* <a href="https://twitter.com/bluetrails_" target="_blank" rel="noopener noreferrer"> twitter</a><br />
    //     <a href="https://www.facebook.com/profile.php?id=100089853300338" target="_blank" rel="noopener noreferrer"> facebook</a><br />
    //     <a href="https://soundcloud.com/bluetrails" target="_blank" rel="noopener noreferrer">soundcloud</a><br /> */}
    // </div>)

    // GRATES
    return (<div>
        <div>GRATES:OUT NOW</div><br />
        <a href="https://open.spotify.com/album/1D0haRtwS6Vqa2vKEyjMfR?si=GLYCOcWMSliz-RISEt6XLA" target="_blank" rel="noopener noreferrer">spotify</a><br />
        <a href="https://music.apple.com/us/album/grates-single/1709783713" target="_blank" rel="noopener noreferrer">apple music</a><br />
        <a href="https://www.instagram.com/bluetrails_/" target="_blank" rel="noopener noreferrer">instagram</a><br />
        <a href="https://www.tiktok.com/@bluetrails" target="_blank" rel="noopener noreferrer">tiktok</a><br />
        <a href="https://youtu.be/8j6JVwUsgwY" target="_blank" rel="noopener noreferrer">youtube</a><br />
        <a href="https://bluetrails.bandcamp.com/track/grates" target="_blank" rel="noopener noreferrer">bandcamp</a><br />
        {/* <a href="https://twitter.com/bluetrails_" target="_blank" rel="noopener noreferrer"> twitter</a><br />
        <a href="https://www.facebook.com/profile.php?id=100089853300338" target="_blank" rel="noopener noreferrer"> facebook</a><br />
        <a href="https://soundcloud.com/bluetrails" target="_blank" rel="noopener noreferrer">soundcloud</a><br /> */}
    </div>)
}
